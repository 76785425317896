<template>
<div>
    <b-row>
        <b-col md="12">
            <b-row>
                <b-col md="12">
                    <CCard>
                        <CCardHeader color="primario" text-color="white">
                            <i class="fas fa-digital-tachograph fa-md mr-1"></i> <span class="h5"> Gestión de informe de experto técnico</span>
                        </CCardHeader>
                        <CCardBody>
                            <b-row>
                                <b-col md="12">
                                    <validation-observer ref="observer" v-slot="{ handleSubmit }">
                                        <b-form @submit.stop.prevent="handleSubmit(registrarTecnicalReport)">
                                            <b-row>
                                                <b-col md="12">
                                                    <validation-provider name="normas" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Normas:" class="mb-2">
                                                            <v-select multiple :reduce="listaNormas => listaNormas.idNorma" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': dataTecnicalReport.normas.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataTecnicalReport.normas" :options="listaNormas">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <!-- <b-col md="8">
                                                    <validation-provider name="cliente" rules="required" v-slot="{errors}">
                                                        <b-form-group label="Cliente:" class="mb-2">
                                                            <v-select :reduce="listaClientes => listaClientes.idAuditoria" label="razonSocial" placeholder="Seleccione una opción" :class="{'style-valid-select': dataTecnicalReport.idAuditoria  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataTecnicalReport.idAuditoria" :options="listaClientes">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col> -->
                                                <b-col md="6">
                                                    <validation-provider name="contrato Nro" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Contrato Nro:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese el nro de contrato" v-model.lazy="dataTecnicalReport.nContrato"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="ubicación de la auditoría" :rules="{required: !isTemplate}" v-slot="validationContext">
                                                        <b-form-group label="Ubicación de la auditoría:" class="mb-2">
                                                            <b-form-input :state="getValidationState(validationContext)" placeholder="Ingrese la ubicación de la auditoría" v-model.lazy="dataTecnicalReport.ubicacionAuditoria"></b-form-input>
                                                            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="tipo de auditoría" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                        <b-form-group label="Tipo de auditoría:" class="mb-2">
                                                            <v-select multiple :reduce="listaTipoAuditoria => listaTipoAuditoria.idTipoAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': dataTecnicalReport.tipoAuditoria.length > 0  , 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataTecnicalReport.tipoAuditoria" :options="listaTipoAuditoria">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>
                                                <b-col md="6">
                                                    <validation-provider name="metodología de auditoría" :rules="!isTemplate ? 'required' : ''" v-slot="{errors}">
                                                        <b-form-group label="Metodología de auditoria:" class="mb-2">
                                                            <v-select :reduce="listaMetodologiaAuditoria => listaMetodologiaAuditoria.idMetodologiaAuditoria" label="descripcion" placeholder="Seleccione una opción" :class="{'style-valid-select': dataTecnicalReport.metodologiaAuditoria, 'style-invalid-select is-invalid': !!errors.length}" v-model.lazy="dataTecnicalReport.metodologiaAuditoria" :options="listaMetodologiaAuditoria">
                                                                <span slot="no-options" class="text-muted">Busqueda no encontrada</span>
                                                            </v-select>
                                                            <div class="invalid-feedback">{{ errors[0]}}</div>
                                                        </b-form-group>
                                                    </validation-provider>
                                                </b-col>

                                                <b-col md="12">
                                                    <b-table-simple bordered show-empty mediun responsive outlined>
                                                        <b-tbody>
                                                            <b-tr>
                                                                <b-td width="55%">
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[0].texto  = $event" :value="dataTecnicalReport.contenido[0].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group class="mb-2" plain v-model="dataTecnicalReport.contenido[0].radio" :options="[ { text: 'Adecuado', value: '1' },  { text: 'Inadecuado', value: '2' },]" />
                                                                    <b-form-group label="Proporcione una justificación de la insuficiencia:">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[0].detalles  = $event" :value="dataTecnicalReport.contenido[0].detalles" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td width="55%" rowspan="2">
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[1].texto  = $event" :value="dataTecnicalReport.contenido[1].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-group label="Proporcione detalles de los requisitos legales y reglamentarios aplicables:">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[1].detalles  = $event" :value="dataTecnicalReport.contenido[1].detalles" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td class="text-center">
                                                                    <b-form-group label="Cumplimiento de los requisitos legales y reglamentarios aplicables:">
                                                                        <b-form-radio-group class="mb-2" plain v-model.lazy="dataTecnicalReport.contenido[1].radio" :options="[ { text: 'SI', value: '1' },  { text: 'NO', value: '2' },]" />
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td width="55%">
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[2].texto  = $event" :value="dataTecnicalReport.contenido[2].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group class="mb-2" plain v-model="dataTecnicalReport.contenido[2].radio" :options="[ { text: 'Aceptable', value: '1' },  { text: 'Inaceptable ', value: '2' }, { text: 'Observación', value: '3' }]" />
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td width="55%">
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[3].texto  = $event" :value="dataTecnicalReport.contenido[3].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group class="mb-2" plain v-model="dataTecnicalReport.contenido[3].radio" :options="[ { text: 'Aceptable', value: '1' },  { text: 'Inaceptable ', value: '2' }, { text: 'Observación', value: '3' }]" />
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td width="55%">
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[4].texto  = $event" :value="dataTecnicalReport.contenido[4].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group class="mb-2" plain v-model="dataTecnicalReport.contenido[4].radio" :options="[ { text: 'Aceptable', value: '1' },  { text: 'Inaceptable ', value: '2' }, { text: 'Observación', value: '3' }]" />
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td width="55%">
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[5].texto  = $event" :value="dataTecnicalReport.contenido[5].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-radio-group class="mb-2" plain v-model="dataTecnicalReport.contenido[5].radio" :options="[ { text: 'Adecuado', value: '1' },  { text: 'Inadecuado ', value: '2' }, { text: 'Observación', value: '3' }]" />
                                                                    <b-form-group label="Proporcione una justificación de la insuficiencia:">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[5].detalles  = $event" :value="dataTecnicalReport.contenido[5].detalles" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <template v-if="!!dataTecnicalReport.normas.find(x => x == '1') ">
                                                                    <b-td width="55%">
                                                                        <b-form-group class="mb-2">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[6].texto  = $event" :value="dataTecnicalReport.contenido[6].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-form-group>
                                                                    </b-td>
                                                                    <b-td class="text-center">
                                                                        <b-form-radio-group class="mb-2" plain v-model="dataTecnicalReport.contenido[6].radio" :options="[ { text: 'Aceptable', value: '1' },  { text: 'Inaceptable ', value: '2' }, { text: 'Observación', value: '3' }]" />
                                                                    </b-td>
                                                                </template>
                                                            </b-tr>
                                                            <template v-if="!!dataTecnicalReport.normas.find(x => x == '2') || !!dataTecnicalReport.normas.find(x => x == '3')">
                                                                <b-tr>
                                                                    <b-td width="55%">
                                                                        <b-form-group class="mb-2">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[7].texto  = $event" :value="dataTecnicalReport.contenido[7].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-form-group>
                                                                    </b-td>
                                                                    <b-td class="text-center">
                                                                        <b-form-radio-group class="mb-2" plain v-model="dataTecnicalReport.contenido[7].radio" :options="[ { text: 'Aceptable', value: '1' },  { text: 'Inaceptable ', value: '2' }, { text: 'Observación', value: '3' }]" />
                                                                    </b-td>
                                                                </b-tr>
                                                                <b-tr>
                                                                    <b-td width="55%">
                                                                        <b-form-group class="mb-2">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.contenido[8].texto  = $event" :value="dataTecnicalReport.contenido[8].texto" placeholder="Escriba aqui..."></b-form-textarea>
                                                                        </b-form-group>
                                                                    </b-td>
                                                                    <b-td class="text-center">
                                                                        <b-form-radio-group class="mb-2" plain v-model="dataTecnicalReport.contenido[8].radio" :options="[ { text: 'Aceptable', value: '1' },  { text: 'Inaceptable ', value: '2' }, { text: 'Observación', value: '3' }]" />
                                                                    </b-td>
                                                                </b-tr>
                                                            </template>
                                                            <b-tr>
                                                                <b-td colspan="2">
                                                                    <b-form-group label="Proporcionar resumen de comentarios / observaciones:">
                                                                        <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.resumen  = $event" :value="dataTecnicalReport.resumen" placeholder="Escriba aqui..."></b-form-textarea>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-group label="Experto técnico:">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.expertoTecnico  = $event" :value="dataTecnicalReport.expertoTecnico" placeholder="Ingrese el experto técnico"></b-form-textarea>
                                                                        </b-form-group>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-group label="Nombre y Fecha:">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.nombreFecha  = $event" :value="dataTecnicalReport.nombreFecha" placeholder="Ingrese el nombre y la fecha"></b-form-textarea>
                                                                        </b-form-group>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                            <b-tr>
                                                                <b-td>
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-group label="Revisado por:">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.revisadoPor  = $event" :value="dataTecnicalReport.expertoTecnico" placeholder="Ingrese el revisor"></b-form-textarea>
                                                                        </b-form-group>
                                                                    </b-form-group>
                                                                </b-td>
                                                                <b-td class="text-center">
                                                                    <b-form-group class="mb-2">
                                                                        <b-form-group label="Lider del equipo de auditoría y fecha:">
                                                                            <b-form-textarea rows="2" max-rows="6" @change="dataTecnicalReport.liderEquipoFecha  = $event" :value="dataTecnicalReport.liderEquipoFecha" placeholder="Ingrese el lider del equipo y fecha"></b-form-textarea>
                                                                        </b-form-group>
                                                                    </b-form-group>
                                                                </b-td>
                                                            </b-tr>
                                                        </b-tbody>
                                                    </b-table-simple>
                                                </b-col>

                                            </b-row>
                                            <b-row class="text-center mt-2">
                                                <b-col md="12">
                                                    <b-button :to="{name: $route.params.eu ? 'ejecutar auditoria' : 'Documentos Stage 2' }" class="mr-2" variant="dark" type="button">
                                                        <i class="fas fa-arrow-left"></i> Volver
                                                    </b-button>
                                                    <b-button variant="success" type="submit">
                                                        <i class="fas fa-save"></i> Guardar
                                                    </b-button>
                                                </b-col>
                                            </b-row>
                                        </b-form>
                                    </validation-observer>
                                </b-col>
                            </b-row>
                        </CCardBody>
                    </CCard>
                </b-col>
            </b-row>
        </b-col>
    </b-row>

</div>
</template>

<script>
import axios from "axios";
import CONSTANTES from "@/Constantes.js";

export default {

    data() {
        return {
            isTemplate: true,
            dataTecnicalReport: {
                idTecnicalReport: '',
                idCliente: null,

                idAuditoria: null,
                normas: [],
                nContrato: '',
                ubicacionAuditoria: '',
                metodologiaAuditoria: null,
                tipoAuditoria: [],

                contenido: [{
                        texto: 'Adecuación de la infraestructura',
                        radio: 1,
                        detalles: '',
                    },
                    {
                        texto: 'Requisitos legales y reglamentarios',
                        radio: 1,
                        detalles: '',
                    }, {
                        texto: 'Adecuación de la validación del proceso',
                        radio: 1,
                    }, {
                        texto: 'Parámetros de proceso',
                        radio: 1,
                    }, {
                        texto: 'Cumplimiento de los requisitos de seguridad',
                        radio: 1,
                    }, {
                        texto: 'Adecuación del plan de calidad para las especificaciones legales y de productos / servicios.',
                        radio: 1,
                        detalles: '',
                    }, {
                        texto: 'Comentarios sobre la justificación de las exclusiones tomadas para el SGC',
                        radio: 1,
                    }, {
                        texto: 'Estudio de adecuación de aspectos e impacto para EMS',
                        radio: 1,
                    }, {
                        texto: 'Análisis de peligros y riesgos para OHSAS-OHSMS',
                        radio: 1,
                    }
                ],
                resumen: '',
                expertoTecnico: '',
                nombreFecha: '',
                revisadoPor: '',
                liderEquipoFecha: '',
            },

            listaClientes: [],
            listaNormas: [{
                    idNorma: 1,
                    descripcion: 'ISO 9001: 2015'
                },
                {
                    idNorma: 2,
                    descripcion: 'ISO 14001: 2015'
                },
                {
                    idNorma: 3,
                    descripcion: 'ISO 45001: 2018'
                },
                {
                    idNorma: 4,
                    descripcion: 'ISO 37001: 2016'
                },
                {
                    idNorma: 5,
                    descripcion: 'ISO 27001: 2013'
                },
                {
                    idNorma: 6,
                    descripcion: 'ISO 20000: 2011'
                },
            ],

            listaMetodologiaAuditoria: [{
                idMetodologiaAuditoria: 1,
                descripcion: 'Auditoría in situ',
            }, {
                idMetodologiaAuditoria: 2,
                descripcion: 'Auditoría remota',
            }],

            listaTipoAuditoria: [{
                idTipoAuditoria: 1,
                descripcion: 'IA-Estadio-II',
            }]
        }

    },
    methods: {

        getValidationState({
            dirty,
            validated,
            valid = null
        }) {
            return dirty || validated ? valid : null;
        },

      
        obtenerTecnicalReport() {
            let me = this;
            axios.defaults.headers.common[
                "Authorization"
            ] = `Bearer ${localStorage.token}`;
            axios
                .get(CONSTANTES.URL_RUTA_SERVICIOS + "cliente/pack/obtener-reporte-experto-tecnico-s2", {
                    params: {
                        idTecnicalReport: me.$route.params.id,
                        idCliente: me.dataTecnicalReport.idCliente,
                    }
                })
                .then(function (response) {

                    me.dataTecnicalReport.idTecnicalReport = response.data[0].idTecnicalReport;
                    me.dataTecnicalReport.idCliente = response.data[0].idCliente;
                    me.dataTecnicalReport.idAuditoria = response.data[0].idAuditoria;
                    me.dataTecnicalReport.normas = JSON.parse(response.data[0].normas);
                    me.dataTecnicalReport.nContrato = response.data[0].nContrato;
                    me.dataTecnicalReport.ubicacionAuditoria = response.data[0].ubicacionAuditoria;
                    me.dataTecnicalReport.metodologiaAuditoria = JSON.parse(response.data[0].metodologiaAuditoria);
                    me.dataTecnicalReport.tipoAuditoria = JSON.parse(response.data[0].tipoAuditoria);
                    me.dataTecnicalReport.contenido = JSON.parse(response.data[0].contenido);
                    me.dataTecnicalReport.resumen = response.data[0].resumen;
                    me.dataTecnicalReport.expertoTecnico = response.data[0].expertoTecnico;
                    me.dataTecnicalReport.nombreFecha = response.data[0].nombreFecha;
                    me.dataTecnicalReport.revisadoPor = response.data[0].revisadoPor;
                    me.dataTecnicalReport.liderEquipoFecha = response.data[0].liderEquipoFecha;

                    if (me.$route.params.eu && me.$route.query.idAuditoria) {
                        me.dataTecnicalReport.isPack = true;
                        me.dataTecnicalReport.idTecnicalReport = me.$route.params.eu == 'u' ? me.$route.params.id : null;
                        me.dataTecnicalReport.idAuditoria = me.$route.query.idAuditoria || null
                        me.dataTecnicalReport.idUsuario = me.$store.state.user.uid;
                        me.dataTecnicalReport.sUsuario = me.$store.state.user.username;
                        me.dataTecnicalReport.eu = me.$route.params.eu == 'u' ? 'u' : 'i'
                    }

               })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                      me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                });
        },
        registrarTecnicalReport() {
            let me = this;

            me.disabled = true;
            axios
                .post(
                    CONSTANTES.URL_RUTA_SERVICIOS + 'cliente/pack/registrar-reporte-experto-tecnico-s2',
                    me.dataTecnicalReport, {
                        headers: {
                            Authorization: `Bearer ${localStorage.token}`
                        },
                    }
                )
                .then(function (response) {
                    let color = response.data.resultado == 1 ? "success" : "error";
                    me.swat(color, response.data.mensaje)
                    // me.modalNuevoColaborador = false;
                    // me.listarColaboradores();
                    me.disabled = false;
                      me.$router.push({
                        name: me.$route.params.eu ?
                            'ejecutar auditoria' : 'Documentos Stage 2',
                    });
                })
                .catch(function (error) {
                    me.swat('error', 'Algo salió mal!')
                    me.disabled = false;
                });

        },

        swat(icon, title) {
            this.$swal.mixin({
                toast: true,
                showConfirmButton: false,
                position: 'bottom-right',
                timer: 3500,
                timerProgressBar: true,
                didOpen: (toast) => {
                    toast.addEventListener('mouseenter', this.$swal.stopTimer)
                    toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                }
            }).fire({
                icon: icon,
                title: title
            });
        },

    },

    async mounted() {
        if (this.$store.state.user) {
            let user = this.$store.state.user;
            this.dataTecnicalReport.idCliente = user.uidClient;
            // await this.listarClientes();
            if (this.$route.params.id) {
                this.obtenerTecnicalReport();
            }
        }

    }

}
</script>

<style>
.valign-middle {
    vertical-align: middle !important;
}


</style>
